import React from 'react';  
import { Outlet,useNavigate,useLocation } from 'react-router-dom';  
import './index.scss'
import logoImg from 'assets/images/logo.jpg'
import logo1Img from 'assets/images/logo1.jpg'
const urlList=[
  {url:'/home',txt:'首页'},
  {url:'/about',txt:'关于我们'},
  {url:'/intelligence',txt:'智能球馆'},
  {url:'/open',txt:'我要开店'},
  {url:'/news',txt:'新闻资讯'},
  {url:'/college',txt:'台球学院'},
]


// 总布局
function Layout() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  // 路由跳转
  const goTo = (url) => {
    navigate(url);
  };
  // 跳转到备案页面
  const goIcp = () => {
    window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');   
  };  
  return (
    <div className='web-layout'>  
      <header>
        <img alt='logo' src={logoImg}/>
        {urlList.map(item=>{
          return <p key={item.url} className={item.url===pathname?'cur':''} onClick={()=>goTo(item.url)}>{item.txt}</p>
        })}
      </header>
      <Outlet /> {/* 子路由内容将渲染在这里 */}
      <footer>
        <img alt='logo' src={logo1Img}/>
        <ul>
          <li>版权所有：北京娱人共享智能科技有限公司</li>
          <li>地址：北京市通州区新华大街58号万达广场A座1709室</li>
          <li className='icp' onClick={goIcp}>京ICP备 2022031993号-1</li>
        </ul>
        <ul>
          <li>全球服务热线</li>
          <li className='tel'>4000-188-147</li>
        </ul>
      </footer>  
    </div>  
  );  
}  
  
export default Layout;